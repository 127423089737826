@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://api.fontshare.com/v2/css?f[]=satoshi@900,700,500,300,401,400&display=swap");

@font-face {
  font-family: "Chillax";
  src: url("../src/fonts/Chillax-Medium.otf");
}

@font-face {
  font-family: "Array";
  src: url("../src/fonts/Array-Semibold.otf");
}

@font-face {
  font-family: "Clash Display";
  src: url("../src/fonts/ClashDisplay-Light.otf");
}

body {
  margin: 0;
  font-family: "Satoshi", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
